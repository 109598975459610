<template>
    <s-form class="filter-grid">
        <div class="pt-2 d-sm-none">
            <p class="font-inter-medium">Filters</p>
        </div>

        <validated-select :options="statusOptions" class="ml-2 c-input-select-b order-4 order-lg-2" placeholder="Status"
                          v-model="filter.status"/>

        <validated-input class="ml-2 order-1 order-lg-3" placeholder="Search Bank Name" v-model="filter.all_search">
            <template #append>
                <i class="fa fa-search"></i>
            </template>
        </validated-input>

        <div class="ml-0 ml-lg-5 order-0 mb-3 mb-lg-0 order-lg-4">
            <router-link to="/add-bank/" v-if="currentUser.is_superuser || currentUser.is_ventura_admin
             || currentUser.permission.can_add_bank">
                <btn class="ml-3" text="Add Bank/FI" type="button" size=""
                     color="secondary"></btn>
            </router-link>
        </div>

    </s-form>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
    name : 'BanksListFilter',

    props : {
        model : {
            type : Object,
            default () {
                return {
                    status     : '',
                    all_search : ''
                };
            }
        }
    },

    data () {
        return {

            statusOptions : [
                { label : 'All', value : 'All' },
                { label : 'Active', value : 'Active' },
                { label : 'Inactive', value : 'Inactive' }
            ]
        };
    },

    computed : {
        filter () {
            return this.model;
        },

        ...mapGetters(['currentUser'])
    }
};
</script>

<style scoped lang="scss">
.filter-grid {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    @media(min-width: 992px) {
        grid-template-columns: 1fr repeat(2, 2fr) 3fr 2fr;
    }
}

.order-1 {
    order: 1;
}
</style>
