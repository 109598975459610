<template>
    <b-form class="row" :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}"
            :initial-data="initialData">

        <div class="col-12 col-lg-6">
            <validated-input label="Bank Name" v-model="model.bank_name" class="c-input-2"
                             :rules="{required: true}" :disabled="loading"></validated-input>
        </div>

        <div class="col-12 col-lg-6">
            <validated-file-input placeholder="Bank Logo" label="Bank Logo" :disabled="loading"
                                  v-model="model.bank_logo" class="c-file-input">
                <template #append>
                    <div class="form-file-input-tag fl-x-cc text-white px-3">
                        <p class="m-0">Upload</p>
                        <img src="../../assets/web/dashboard/icons/arrow-up-16.png" alt="">
                    </div>
                </template>
            </validated-file-input>
        </div>

        <div class="col-12">
            <div class="fl-x fl-j-c mt-4">
                <div class="btn-group">
                    <!--                    <btn text="Cancel" @click="cancelOperation" type="button" class="px-3"-->
                    <!--                         design="basic-b" color="secondary"></btn>-->

                    <btn text="Update Bank" :loading="loading" loading-text="Updating..." class="px-3"
                         design="basic-a" color="secondary"></btn>
                </div>
            </div>

        </div>
    </b-form>
</template>

<script>
import urls from '@/data/urls';

export default {
    name : 'EditBank',

    props : {
        initialData : {
            type : Object
        }
    },

    data () {
        return {
            addUrl : urls.auction.bank.addEdit,

            statusOptions : [
                { label : 'Active', value : 'Active' },
                { label : 'Inactive', value : 'Inactive' }
            ]
        };
    },

    methods : {
        formSuccess () {
            this.$notify('Successfully Updated', 'Success', { type : 'success' });
            this.$emit('success');
        },

        cancelOperation () {
            this.$emit('cancelOperation');
        }
    }
};
</script>

<style scoped>

</style>
