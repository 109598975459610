<template>
    <div class="d-lg-none">

        <div v-if="loading">
            <div class="card bg-info mt-4">
                <loading-animation/>
                Please wait while loading data from the server.
            </div>
        </div>

        <div v-else-if="error">
            <div class="card bg-danger mt-4">
                <error-icon/>
                Failed to load data from the server.
            </div>
        </div>

        <div v-else>

            <div v-for="(i, k) in details.data" v-bind:key="k" class="mt-4">

                <div class="gr-3-cols">

                    <div class="fl-gr-1 b-1 py-2">
                        <!--                        <div class="fl-x fl-j-c fl-a-c">-->
                        <div class="text-center">
                            <div class="pos-r">
                                <!--                                <div class="line-right-1"></div>-->
                                <span class="font-inter-medium">Sl No</span>
                            </div>
                        </div>
                    </div>

                    <!--                    <div class="fl-gr-1">-->
                    <!--                        <div class="fl-x fl-j-s fl-a-c">-->
                    <!--                            <div class="pos-r">-->
                    <!--                                <div class="line-right-1"></div>-->
                    <!--                                <span class="font-inter-medium">Bank Id</span>-->
                    <!--                            </div>-->
                    <!--                        </div>-->
                    <!--                    </div>-->

                    <div class="fl-gr-1 b-1 py-2">
                        <div class="text-center">
                            <div class="pos-r text-center">
                                <!--                                <div class="line-right-1"></div>-->
                                <span class="font-inter-medium">Bank/FI Logo</span>
                            </div>
                        </div>
                    </div>

                    <div class="fl-gr-1 b-1 py-2">
                        <div class="text-center">
                            <div class="pos-r text-center">
                                <!--                                <div class="line-right-1"></div>-->
                                <span class="font-inter-medium">Bank/FI Name</span>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="b-1 bs-3">
                    <div class="gr-3-cols font-inter-medium c-line-bottom">

                        <div class="b-1 py-2">
                            <div class="fl-x fl-j-c fl-a-c">
                                <div class="pos-r">
                                    <!--                                    <div class="line-right-1"></div>-->
                                    {{ i.sl_no }}
                                </div>
                            </div>
                        </div>
                        <div class="b-1 py-2 fl-x-cc">
                            <div class="logo-container">
                                <img v-if="i.bank_logo" class="img-fluid" :src="i.bank_logo"
                                     alt="#BankLogo">
                            </div>
                        </div>

                        <div class="b-1 py-2">
                            <div class="pos-r text-center">
                                <!--                                <div class="line-right-1"></div>-->
                                {{ i.bank_name }}
                            </div>
                        </div>
                        <!--                        <div class="py-2 b-1">-->
                        <!--                            <div class="pos-r text-center">-->
                        <!--                                &lt;!&ndash;                                <div class="line-right-1"></div>&ndash;&gt;-->
                        <!--                                {{ i.active_users }}-->
                        <!--                            </div>-->
                        <!--                        </div>-->

                    </div>

                    <div class="row b-1 fs--1 m-0">

                                        <span class="b-1 w-100 col-6">
                                            <div class="p-2">
                                               <span class="font-inter-medium">Active Users</span> <span class="ml-2">{{
                                                    i.active_users
                                                }}</span>
                                            </div>
                                        </span>
                        <span class="b-1 w-100 col-6">
                    <div class="p-2">
                       <span class="font-inter-medium">Inactive Users</span> <span class="ml-2">{{
                            i.inactive_users
                        }}</span>
                    </div>
                </span>

                        <span class="b-1 w-100 col-12">
                    <div class="p-2">
                       <span class="font-inter-medium">Status</span> <span class="ml-2">
                <activate-suspend-buttons v-if="currentUser.is_superuser || currentUser.is_ventura_admin
                                     || currentUser.permission.can_edit_bank"
                                          :active="i.status === 'Active'"
                                          @activate="setIsActive(i, 'Active')"
                                          @suspend="setIsActive(i, 'Inactive')">
                </activate-suspend-buttons>
                <span v-else>{{ i.status }}</span>
                    </span>
                    </div>
                </span>

                        <span class="b-1 col-12">
                        <div class="p-1 fl-x fl-j-c py-3">
                            <div class="btn-group">
                                <btn design="" icon="fa fa-eye"
                                     class="border-gray-hover bg-transparent btn-icon p-2 text-5"
                                     @click="setView(i)"
                                     v-if="currentUser.is_superuser || currentUser.is_ventura_admin"/>

                                <btn design="" icon="fa fa-pencil"
                                     class="border-gray-hover bg-transparent btn-icon p-2 text-5"
                                     @click="setEdit(i)"
                                     v-if="currentUser.is_superuser || currentUser.is_ventura_admin
                                     || currentUser.permission.can_edit_bank"/>
                            </div>
                        </div>
                    </span>
                    </div>

                </div>
            </div>
            <div v-if="details.data.length !== 0" class="mt-3 fl-te-c-res">
                <div class="mb-2">
                    Showing {{ details.from }} to {{ details.to }} of {{ details.total }} entries
                </div>
                <div>
                    <btn class="m-1" size="sm" @click="prevClicked" v-if="prev" type="button"
                         icon="fa fa-chevron-left"/>
                    <btn class="m-1" size="sm" disabled v-else type="button" icon="fa fa-chevron-left"/>
                    <btn class="m-1" size="sm" type="button">{{ details.current_page }}</btn>
                    <btn class="m-1" size="sm" @click="nextClicked" v-if="next" type="button"
                         icon="fa fa-chevron-right"/>
                    <btn class="m-1" size="sm" disabled v-else type="button" icon="fa fa-chevron-right"/>
                </div>
            </div>

            <div v-if="details.data.length === 0" class="card">
                <div class="mb-2">
                    <div>
                        <div class="fl-x fl-j-c fl-a-c">
                            No Data
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'secure-axios';
import urls from '../../data/urls';
import ActivateSuspendButtons from '@components/manage-users/ui/ActivateSuspendButtons';
import _ from 'lodash';

export default {
    name : 'BankListCardMobileView',

    components : { ActivateSuspendButtons },

    props : {
        status : {
            type : String
        },

        all_search : {
            type : String
        }
    },

    watch : {
        status () {
            this.timeOutLoadData();
        },
        all_search () {
            this.timeOutLoadData();
        }
    },

    data () {
        return {
            prev     : '',
            next     : '',
            page     : '1',
            per_page : '10',
            loading  : true,
            error    : false,
            details  : null
        };
    },

    computed : {
        ...mapGetters(['currentUser'])
    },

    mounted () {
        this.loadData();
    },

    methods : {
        timeOutLoadData : _.debounce(function () {
            this.loadData();
        }, 2000),
        loadData () {
            const that = this;

            that.loading = true;
            that.error = false;

            axios.get(urls.auction.bank.list, {
                params : {
                    page       : that.page,
                    per_page   : that.per_page,
                    status     : that.status,
                    all_search : that.all_search
                }
            }).then(function (response) {
                const json = response.data;
                that.details = { ...json };
                that.loading = false;
                that.error = false;
                that.setPagination();
            }).catch(function (exception) {
                console.log('exception : ', exception);
                that.loading = false;
                that.error = true;
            });
        },
        setPagination () {
            this.prev = parseInt(this.details.current_page) !== this.details.from;
            this.next = parseInt(this.details.current_page) !== this.details.last_page;
        },
        prevClicked () {
            this.page = parseInt(this.page) - 1;
            this.loadData();
        },
        nextClicked () {
            this.page = parseInt(this.page) + 1;
            this.loadData();
        },

        toggleUserActiveStatus (i) {
            this.items[i].active = !this.items[i].active;
        },

        setView (item) {
            this.$emit('view', item);
        },

        setIsActive (user, status) {
            user.loading = true;
            const that = this;

            const permission = this.checkPermission(status);

            if (!permission) {
                alert('Don\'t have permission for this Action..!');
                return;
            }

            const model = {
                id     : user.id,
                status : status
            };

            axios.form(urls.auction.bank.statusUpdate, model).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    user.loading = false;
                    user.status = status;
                    that.loadData();
                } else {
                    user.loading = false;
                    user.status = status;
                }
            }).catch(function (exception) {
                console.log('exception occurred : ', exception);
                user.is_active = !status;
            });
        },

        setEdit (item) {
            this.$emit('edit', item);
        },
        checkPermission (status) {
            if (status === 'Active') {
                if (this.currentUser.is_superuser) {
                    return true;
                } else {
                    return this.currentUser.permission.can_aprove_bank;
                }
            } else {
                if (this.currentUser.is_superuser) {
                    return true;
                } else {
                    return this.currentUser.permission.can_reject_bank;
                }
            }
        }

    }

};
</script>

<style scoped lang="scss">
.gr-4-cols {
    display: grid;
    grid-template-columns:repeat(4, 1fr);
}

.gr-3-cols {
    display: grid;
    grid-template-columns:repeat(3, 1fr);
}

.c-line-bottom {
    position: relative;

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        height: 1px;
        width: 100%;
        background-color: rgba(128, 128, 128, 0.3);
    }
}

.icon-danger {
    i {
        color: var(--color-danger) !important;
    }
}

.icon-primary {
    i {
        color: var(--color-primary) !important;
    }
}

.logo-container {
    width: 2.8rem;
    height: auto;
}
</style>
