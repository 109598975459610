<template>
    <div class="p-4">
        <div class="b-1 round-1 py-5">

            <div class="col-12">
                <div class="fl-x fl-j-c">
                    <div class="w-h-5re pos-r">

                        <img v-if="item.bank_logo" :src="item.bank_logo" alt=""
                             class="b-rounded w-h-100"/>

                        <img v-else src="../../assets/web/homee/avatar.jpg" alt=""
                             class="b-rounded outline-secondary w-h-100"/>

                    </div>
                </div>
            </div>

            <div class="row">

                <div class="col-12 mt-2 fs-lg-1">
                    <div class="tab-details-contain ">
                        <div class="fl-x fl-j-e item-min-width">
                            <div class="fl-x fl-j-e">
                                <div class="data-label pl-5 pl-lg-0 fs--1 fs-lg-1 pr-2 pr-lg-0">Bank Name</div>
                            </div>
                            <span class="px-3">:</span>
                            <div class="fl-x fl-j-s item-min-width pl-3">
                            <span class="">
                                <span class="font-inter-medium">{{ item.bank_name }}</span>
                            </span>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="col-12 mt-lg-0 mt-2">
                    <div class="tab-details-contain">
                        <div class="fl-x fl-j-e item-min-width">
                            <div class="fl-x fl-j-e">
                                <div class="data-label fs--1 fs-lg-1 pr-2 pr-lg-0">Status</div>
                            </div>
                            <span class="px-3">:</span>
                            <div class="fl-x fl-j-s item-min-width pl-3">
                            <span class="">
                                <span class="font-inter-medium">{{ item.status }}</span>
                            </span>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="col-12 mt-lg-0 mt-2">
                    <div class="tab-details-contain">
                        <div class="fl-x fl-j-e item-min-width ">
                            <div class="fl-x fl-j-e">
                                <div class="data-label fs--1 fs-lg-1 pr-2 pr-lg-0">Active Users</div>
                            </div>
                            <span class="px-3">:</span>
                            <div class="fl-x fl-j-s item-min-width pl-3">
                            <span class="">
                                <span class="font-inter-medium">{{ item.active_users }}</span>
                            </span>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="col-12 mt-lg-0 mt-2">
                    <div class="tab-details-contain">
                        <div class="fl-x fl-j-e item-min-width ">
                            <div class="fl-x fl-j-e">
                                <div class="data-label fs--1 fs-lg-1">Inactive Users</div>
                            </div>
                            <span class="px-3">:</span>
                            <div class="fl-x fl-j-s item-min-width pl-3">
                            <span class="">
                                <span class="font-inter-medium">{{ item.inactive_users }}</span>
                            </span>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="col-12 mt-lg-0 mt-2">
                    <div class="tab-details-contain">
                        <div class="fl-x fl-j-e item-min-width ">
                            <div class="fl-x fl-j-e">
                                <div class="data-label pl-5 pl-lg-5 fs--1">Created By</div>
                            </div>
                            <span class="px-3">:</span>
                            <div class="fl-x fl-j-s item-min-width pl-3">
                            <span class="">
                                <span class="font-inter-medium">{{ item.created_by || item.created_by_name }}</span>
                            </span>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="col-12 mt-lg-0 mt-2">
                    <div class="tab-details-contain">
                        <div class="fl-x fl-j-e item-min-width ">
                            <div class="fl-x fl-j-e item-min-width">
                                <div class="data-label fs--1 fs-lg-1  pl-lg-0 pl-6">Created Date</div>
                            </div>
                            <span class="px-3">:</span>
                            <div class="fl-x fl-j-s item-min-width pl-3">
                            <span class="">
                                <span class="font-inter-medium">{{ item.created_date }}</span>
                            </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12">
                    <div class="fl-x fl-j-c mt-4">
                        <btn text="Back" size="sm" color="secondary" class="px-5" @click="BackButton"></btn>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name : 'ViewBankDetails',

    props : {
        item : {
            type : Object
        }
    },

    methods : {
        BackButton () {
            this.$emit('back');
        }
    }
};
</script>

<style scoped lang="scss">
.avatar-contain {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5rem;
    height: 5rem;
    border-radius: 50%
}

.cell-item {
    display: flex;
    justify-content: space-between;
    @media (min-width: 992px) {
        padding: 0.875rem 0.3125rem;
    }
    //border-left: 1px solid gray;
    //border-right: 1px solid gray;
    //border-top: 1px solid gray;
    position: relative;
    width: 50%;
}

.item-min-width {
    min-width: 50%;
}

.data-label {
    font-size: 16px;
    font-weight: 400;
    //display: inline-flex;
    //width: 70%;
    //min-width: 7rem;
}

.value-item b {
    font-size: 18px;
}

.tab-details-contain {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.tag-item-bg {
    background: url("../../assets/web/bg/header.png");
}

.w-h-5re{
    width:5.5rem !important;
    height:5.5rem !important;
}
</style>
