<template>
    <b-form class="row" :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}"
            :initial-data="initialData" :pre-submit="preSubmit">

        <div class="col-12 col-lg-12">
            <validated-vue-select label="Status" v-model="model.status" :options="statusOptions"
                                  :rules="{required: true}" :disabled="loading"></validated-vue-select>
        </div>

        <div class="col-12">

            <div class="fl-x fl-j-c mt-4">
                <div class="btn-group">
                    <btn text="Update Status" :loading="loading" loading-text="Updating..." class="px-3"
                         design="basic-a" color="secondary"></btn>
                </div>
            </div>

        </div>
    </b-form>
</template>

<script>
import urls from '@/data/urls';

export default {
    name : 'statusUpdate',

    props : {
        initialData : {
            type : Object
        }
    },

    data () {
        return {
            addUrl : urls.auction.bank.statusUpdate,

            statusOptions : [
                { label : 'Active', value : 'Active' },
                { label : 'Inactive', value : 'Inactive' }
            ]
        };
    },

    methods : {
        formSuccess () {
            this.$notify('Successfully Updated', 'Success', { type : 'success' });
            this.$emit('success');
        },

        cancelOperation () {
            this.$emit('cancelOperation');
        },

        preSubmit (model, actualSubmit) {
            const conform = confirm('If you SUSPEND The bank all user in the bank will be suspended. \n \n Are Your Sure ?');

            if (conform) {
                actualSubmit();
            } else {

            }
        }
    }
};
</script>

<style scoped>

</style>
