<template>

    <inner-page-card class="mt-4" heading="Bank/FIs List" :scrollbar="true">

        <banks-list-filter :model="filter"/>

        <custom-vue-table class="d-sm-none" :show-search-box="false" :fields="fields"
                          :extra-params="{
                    status: filter.status,
                    all_search: filter.all_search
                   }" :url="dataUrl" :per-page="10" ref="table">

            <template slot="bank_logo" slot-scope="props">
                <div class="logo-container">
                    <img v-if="props.rowData.bank_logo" class="img-fluid" :src="props.rowData.bank_logo"
                         alt="#BankLogo">
                </div>
            </template>

            <template slot="status" slot-scope="props">
                <activate-suspend-buttons v-if="currentUser.is_superuser || currentUser.is_ventura_admin
                                     || currentUser.permission.can_edit_bank"
                                          :active="props.rowData.status === 'Active'"
                                          @activate="setIsActive(props.rowData, 'Active')"
                                          @suspend="setIsActive(props.rowData, 'Inactive')">
                </activate-suspend-buttons>
                <span v-else>{{ props.rowData.status }}</span>
            </template>

            <template slot="actions" slot-scope="props">
                <div class="fl-x fl-j-sa">
                    <btn design="" icon="fa fa-eye" class="border-gray-hover bg-transparent btn-icon p-2 text-5"
                         @click="setView(props.rowData)"
                         v-if="currentUser.is_superuser || currentUser.is_ventura_admin"/>

                    <btn design="" icon="fa fa-pencil" class="border-gray-hover bg-transparent btn-icon p-2 text-5"
                         @click="setEdit(props.rowData)"
                         v-if="currentUser.is_superuser || currentUser.is_ventura_admin
                            || currentUser.permission.can_edit_bank"/>
                </div>
            </template>

        </custom-vue-table>

        <modal title="Bank Update" ref="editModal" :no-close-on-backdrop="true" width="60r"
               header-color="primary">

            <edit-bank :initial-data="editingItem" @success="formSuccess"
                       @cancelOperation="$refs.editModal.close();"/>
        </modal>

        <modal title="Status Update" ref="statusUpdateModal" :no-close-on-backdrop="true" width="30r"
               header-color="primary">

            <status-update :initial-data="editingItem" @success="formSuccess"
                           @cancelOperation="$refs.statusUpdateModal.close();"/>
        </modal>

        <modal title="Bank Details" ref="ViewModal" :no-close-on-backdrop="true" width="60r"
               header-color="primary">
            <view-bank-details :item="editingItem" @back="$refs.ViewModal.close();"/>
        </modal>

        <bank-list-card-mobile-view :all_search="filter.all_search" :status="filter.status" :key="componentKey"
                                    class="d-lg-none" @edit="setEdit"
                                    @view="setView"></bank-list-card-mobile-view>

    </inner-page-card>

</template>

<script>
import BanksListFilter from '../../views/banks/BanksListFilter';
import BankListCardMobileView from '../../views/banks/BankListCardMobile';
import ActivateSuspendButtons from '@components/manage-users/ui/ActivateSuspendButtons';
import ViewBankDetails from '@/views/banks/ViewBankDetails';
import statusUpdate from '@/views/banks/statusUpdate';
import EditBank from '@/views/banks/EditBank';
import urls from '../../data/urls';
import axios from 'secure-axios';
import { mapGetters } from 'vuex';

export default {
    name : 'BanksList',

    components : {
        BankListCardMobileView,
        BanksListFilter,
        EditBank,
        statusUpdate,
        ViewBankDetails,
        ActivateSuspendButtons
    },

    computed : {
        ...mapGetters(['currentUser'])
    },

    data () {
        return {
            dataUrl : urls.auction.bank.list,

            componentKey : 0,
            status       : this.$route.params.status,

            filter : {
                status : '',
                search : ''
            },

            editingItem : null,

            fields : [
                {
                    name      : 'sl_no',
                    dataClass : 'aligned text-center',
                    title     : 'Sl. No'
                },
                {
                    name      : '__slot:bank_logo',
                    dataClass : 'aligned text-center',
                    title     : 'Bank/FI Logo'
                },
                {
                    name      : 'bank_name',
                    dataClass : 'aligned text-center',
                    title     : 'Bank/FI Name'
                },
                {
                    name      : 'active_users',
                    dataClass : 'aligned text-center',
                    title     : 'Active Users'
                },
                {
                    name      : 'inactive_users',
                    dataClass : 'aligned text-center',
                    title     : 'Inactive Users'
                },
                {
                    name      : '__slot:status',
                    dataClass : 'aligned text-center',
                    title     : 'Status'
                },
                {
                    name       : '__slot:actions',
                    titleClass : 'center aligned text-right',
                    dataClass  : 'aligned text-right',
                    title      : 'Actions'
                }
            ]
        };
    },

    watch : {
        '$route.params.status' : {
            handler () {
                this.setStatus();
            }
        }
    },

    beforeMount () {
        this.setStatus();
    },

    methods : {
        setStatus () {
            if (this.status) {
                this.filter.status = this.$route.params.status;
            }
        },
        formSuccess () {
            this.$refs.editModal.close();
            this.$refs.statusUpdateModal.close();
            this.editingItem = null;
            this.$refs.table.refreshTable();
            this.componentKey += 1;
        },

        setView (item) {
            this.editingItem = { ...item };
            this.$refs.ViewModal.show();
        },

        setEdit (item) {
            this.editingItem = { ...item };
            this.$refs.editModal.show();
        },

        bankSuspend (item) {
            this.editingItem = { ...item };
            this.$refs.statusUpdateModal.show();
        },

        setIsActive (user, status) {
            user.loading = true;
            const that = this;

            const permission = this.checkPermission(status);

            if (!permission) {
                alert('Don\'t have permission for this Action..!');
                return;
            }

            const model = {
                id     : user.id,
                status : status
            };

            axios.form(urls.auction.bank.statusUpdate, model).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    user.loading = false;
                    user.status = status;
                    that.$refs.table.refreshTable();
                } else {
                    user.loading = false;
                    user.status = status;
                }
            }).catch(function (exception) {
                console.log('exception occurred : ', exception);
                user.is_active = !status;
            });
        },

        checkPermission (status) {
            if (status === 'Active') {
                if (this.currentUser.is_superuser) {
                    return true;
                } else {
                    return this.currentUser.permission.can_aprove_bank;
                }
            } else {
                if (this.currentUser.is_superuser) {
                    return true;
                } else {
                    return this.currentUser.permission.can_reject_bank;
                }
            }
        }

    }
};
</script>

<style scoped>
.logo-container {
    width: 2.8rem;
    height: auto;
}

</style>
